import { isAddressFormat } from '@/app/utils/helper'
import { SearchStatusType } from '@/types/enum'

export default defineNuxtRouteMiddleware((to) => {
  const appStore = useAppStore()

  if (to.name === 'account-account') {
    if (!to.params.account) {
      appStore.$patch({
        searchStatus: SearchStatusType.AccountInvalid,
        searchParam: to.params.account
      })

      return navigateTo('/404')
    }

    const routeAccountParam = Array.isArray(to.params.account)
      ? to.params.account[0]
      : to.params.account

    if (!isAddressFormat(routeAccountParam)) {
      appStore.$patch({
        searchStatus: SearchStatusType.AccountInvalid,
        searchParam: routeAccountParam
      })

      return navigateTo('/404')
    }
  }
})
